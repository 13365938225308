import React from "react";
import * as styles from "./scene.module.scss"

export default function Scene({sid, children, addClass=''}) {
  const ch = React.Children.map(children, (child) => {
    return child && child.type ? React.cloneElement(child, {
      sid: sid
    }) : child;
  })
  return (
    <>
      <div className={`${styles.scene} ${addClass}`}>
        <div className={styles.view}>
          {ch}
        </div>
      </div>
    </>
  )
}