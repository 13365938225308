import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";

import animBasic from "../../modules/animation/anim-basic";
import bodymovin from "lottie-web"

const AnimeLottie = ({sid, className, delay = 0 , start=0, options={}, fullTime,jsonAnim, size={w:'100vmax',h:'100vmax'}}) => {
  const elRef = useRef()
  const timeline = useSelector(state => state.scenes[sid].timeline);
  //const jsonAnim =  '/lottie/demo/data.json'//useSelector(state => state.stories.listStories[storyId].transition);
  const [windowSize, setWindowSize] = useState({w: 1440, h: 810, s: 1, r: 0})


  const takeWindowSize = ()=> {
    let w = window.innerWidth;
    let h = window.innerHeight;
    // let s = w/h >=1.77777 ? w/1440 : h/810;
    let s = w/h >=1 ? w/960 : h/960;
    let r = 0; // w/h <1 ? 90 : 0;

    setWindowSize({w, h, s, r});
  }

  useEffect(()=>{
    const targets = elRef.current;
    let anime = null;
    let lottie = null;

    if (targets) {
      takeWindowSize();
      //console.log(lottieSrc)
      targets.lottie = bodymovin.loadAnimation({
        container: elRef.current, // the dom element that will contain the animation
        renderer: 'svg',
        width: 960, // 1440,
        loop: false,
        autoplay: false,
        path: jsonAnim,//'/lottie/demo/data.json' // the path to the animation json
      });
      targets.frames = {frame: 0}
      targets.anime = animBasic(targets.frames, delay,{...options});

      anime = targets.anime;
      lottie = targets.lottie

      window.addEventListener('resize', takeWindowSize);

      return () => {
        if (anime) { anime.remove(targets) }
        if (lottie) { lottie.destroy() }
        window.removeEventListener('resize', takeWindowSize);
      };
    }
  },[delay, options])

  useEffect(()=>{
    const targetsAnime = 'anime' in elRef.current ? elRef.current.anime : null;
    if (targetsAnime) {
      targetsAnime.seek(targetsAnime.duration * timeline / fullTime);
      // console.log(elRef.current.frames.frame);
      elRef.current.lottie.goToAndStop(elRef.current.frames.frame, true);
    }
  },[timeline, fullTime]);


  return (
    <div className={`${className}`} ref={elRef} style={{width: size.w, height: size.h}}> </div>
  )
}

export default AnimeLottie;

