import * as React from "react";
import * as styles from "./heroCard.module.scss"
import {getRandomArbitrary} from "../../modules/mathfun/mathfun";


export default function HeroCard({emojiUrl, label, inAnim, caption, isOutCard}) {

  return (
    <>
      <div className={`${styles.card} ${styles[inAnim]}`} style={{transform: `rotate(${getRandomArbitrary(-20, 20)}deg)`}} >
        <div className={styles.wrap}>
          <div className={styles.label}> {label} </div>
          <div className={styles.pict}>
            {/*<StaticImage src={'../../images/emoji-1.png'} alt={''}/>*/}
            <img src={emojiUrl} alt={''} />
          </div>
          <div className={styles.text}> {caption} </div>
        </div>
      </div>
    </>
  )
}
