import React from "react";
import * as styles from './card.module.scss'

export default function Card({pict, caption, link, text, time, bubbles, type='1'}) {

  return (
    <>
      <div className={`${styles.card} ${styles['type_'+type]}`}>
        <div className={`${styles.wrap}`}>
          { pict && <div className={styles.pictWrap}>
              <img className={styles.pict} src={pict} alt={''}/>
            </div>
          }
          { caption && !link &&
            <div className={styles.caption}>
              {caption}
            </div>
          }
          { caption && link &&
            <a className={styles.caption} href={link}>
              {caption}
            </a>
          }
          { time && <div className={styles.time}>{time}</div> }
          <div className={styles.text}>{text}</div>
          { bubbles && bubbles.map((b,k)=>(
            <div className={styles.bubble}>{b.text}</div>
          ))}
        </div>
      </div>
    </>
  )
}