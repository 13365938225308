import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import anime from "animejs/lib/anime.es.js";
import {setTimeline} from "../../redux/actions";

export default function SceneControl({sid, onTimeEnd = ()=>{}, options={}, pauseFunN=[], cycleFunN=[]}) {
  const timeline = useSelector(store => store.scenes[sid].timeline)
  const fullTime = useSelector(store => store.scenes[sid].fullTime)
  const dispatch = useDispatch()

  useEffect(()=>{
    const targets = {timeline: timeline};
    const animation = anime({
      targets,
      timeline: [
        { value: 0, duration: 0 },
        { value: fullTime },
      ],
      duration: fullTime*10, // x10 speed
      easing: 'linear',
      ...options,
      update: function(anim) {
        cycleHandler({cycleFunN,timeline: targets.timeline, fullTime,anim})
        /*
        let pList = pauseFunN.filter(pf=>{
          return (!pf.hasOwnProperty('one') && pf.timestamp <=targets.timeline)
        });
        if (pList.length > 0) {
          pList[0].one = true;
          anim.pause()
          pList[0].fun(anim);
        }
        */
        dispatch(setTimeline({sid, timeline:targets.timeline}))
      }
    })

    return () => {
      if (animation) anime.remove(targets);
    };
  },[])

  if (timeline >= fullTime) {
    onTimeEnd();
  }
  return <></>
}


function cycleHandler({cycleFunN,timeline, fullTime,anim}) {
  let iList = cycleFunN.filter(pf=>{
    return (!pf.hasOwnProperty('isStarted') && pf.timestamp <= timeline)
  });
  if (iList.length > 0) {
    iList[0].isStarted = true;
  }
  let sList = cycleFunN.filter(pf=>{
    return (pf.hasOwnProperty('isStarted') && !pf.hasOwnProperty('isFinished'))
  });
  if (sList.length > 0) {
    if (sList[0].funGap()) {
      sList[0].isFinished = true
      if (sList[0].hasOwnProperty('fun')) { sList[0].fun() }
    } else {
      if ( timeline >= sList[0].end) { //console.log(sList[0].start)
        anim.pause();
        anim.seek((sList[0].start/fullTime) * anim.duration)
        anim.play();
      }
    }
  }
}