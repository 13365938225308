import {useSelector} from "react-redux";
import {SID_GAME} from "./scenesTypes";
import Scene from "../components/scene/scene";
import React from "react";

import SceneControl from "../components/scene/sceneControl";
import TipsButton from "../components/tipsButton/tipsButton";
import Game from "../components/game/game";

export default function SceneGame() {
  const currentSid = useSelector(store => store.scenes.current)

  return (
    <>
      { (currentSid.indexOf(SID_GAME) >= 0) &&
      <Scene sid={SID_GAME}>
        <Game />
        <TipsButton />

        <SceneControl
          options={{loop: true}}
          />
      </Scene>
      }
    </>
  )
}