import anime from "animejs/lib/anime.es.js";

const animBasic = function (targets, delay = 0, options={}) {
  let cleanOptions = options//JSON.parse(JSON.stringify(options))
  let settings = {
    ...cleanOptions,
    easing: 'linear',
    autoplay: false,
   // loop: true,
    delay: delay,
  }

  return anime({targets, ...settings});
}

export default animBasic;