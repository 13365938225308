import * as styles from "../../compositions/sceneGame.module.scss";
import {openPopup} from "../../redux/actions";
import dataJson from "../../../static/contant_guide.json";
import AnimeLottie from "../animeLottie/animeLottie";
import {SID_GAME} from "../../compositions/scenesTypes";
import React from "react";
import {useDispatch} from "react-redux";

export default function TipsButton() {
  const dispatch = useDispatch()

  return (
    <>
      <div className={styles.wrapTips}
           onClick={()=>{
             dispatch(openPopup({compList: dataJson}))
           }}>
        <AnimeLottie
          sid={SID_GAME}
          className={styles.tips}
          fullTime={1000}
          size = {{w: '200%', h: 'auto'}}
          options={{frame: [
              { value: 0, duration: 0 },
              { value: 250 },
            ]}}
          jsonAnim={'/lottie/guide.json'}
        />
      </div>
      <div className={styles.wrapTipsMobile}
           onClick={()=>{
             dispatch(openPopup({compList: dataJson}))
           }}>Guide</div>
    </>
  )
}