import * as React from "react";
import * as styles from "./layout.module.scss"
import Header from "../header/header";

export default function Layout({children}) {
  return (
    <div className={styles.layout}>
      <Header/>
      <div className={styles.layoutContainer}>
        {children}
      </div>
    </div>
  )
}