import React from "react";
import * as styles from './bigPopup.module.scss';
import ContentJson from "../contentJson/contentJson";
import {useDispatch, useSelector} from "react-redux";
import {closePopup} from "../../redux/actions";

export default function BigPopup() {
  const isPopup = useSelector(stage=>stage.popup.isPopup)
  const compList = useSelector(stage=>stage.popup.compList)
  const dispatch = useDispatch()

  return (
    <>
      {isPopup &&
        <div className={styles.bigPopup}>
          <div className={styles.close} onClick={()=>{dispatch(closePopup())}}>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M31.6429 2L2 32" stroke="#180B3F" stroke-width="4" stroke-linecap="round"/>
              <path d="M2.35714 2L32 32" stroke="#180B3F" stroke-width="4" stroke-linecap="round"/>
            </svg>
          </div>
          <div className={styles.container}>
            <ContentJson styles={{...styles}} compList={compList}/>
          </div>
        </div>
      }
    </>
  )
}