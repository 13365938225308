import React from "react";
//import dataJson from "/static/contant_about.json";
import {getRandomArbitrary} from "../../modules/mathfun/mathfun";
import Card from "../card/card";
import PictBlock from "../pictBlock/pictBlock";

export default function ContentJson({styles, compList}) {
  return (
    <>
      {compList.map((item, key)=>{
        switch (item.comp) {
          case 'wrapCenter':
            return (
              <div key={key} className={styles.wrapCenter}>
                <ContentJson styles={styles} compList={item.children}/>
              </div>
            );
          case 'footer':
            return (
              <div key={key} className={styles.footer}>
                <ContentJson styles={styles} compList={item.children}/>
              </div>
            );
          case 'cards':
            return (
              <div key={key} className={styles.cards}>
                <ContentJson styles={styles} compList={item.children}/>
              </div>
            );
          case 'title':
            return (
              <div key={key} className={styles.title}>
                {item.params.text}
              </div>
            );
          case 'tags':
            return (
              <div key={key} className={styles.tags}>
                {item.params.tags.map((t,k)=>(
                  <div key={k} className={styles.tag} style={{transform: `rotate(${getRandomArbitrary(-4, 4)}deg)`}}>{t.name}</div>
                ))}
              </div>
            );
          case 'lid':
            return (
              <div className={styles.lid} key={key}>
                {item.params.text}
              </div>
            );
          case 'card':
            return (
              <Card key={key}
                    type={item.params.type || 1}
                    caption={item.params.caption}
                    link={item.params.link}
                    pict={item.params.pict}
                    text={item.params.text}
                    bubbles={item.params.bubbles}
                    time={item.params.time}
              />
            )
          case 'pict_block':
            return (
              <PictBlock key={key} src={item.params.src} />
            );
          default:
            return (<strong  key={key}>---</strong>)
        }
      }) }
    </>
  )
}