import * as React from "react"
import Layout from "../components/layout/layout";
import SceneLoading from "../compositions/sceneLoading";
import SceneWelcome from "../compositions/sceneWelcome";
import SceneTransition from "../compositions/sceneTransition";
import SceneGame from "../compositions/sceneGame";

import Seo from "../components/seo/seo";
// import {useSelector} from "react-redux";
// import DebugTimeline from "../components/debugTimeline/debugTimeline";

// markup
const IndexPage = () => {
  // const currentSid = useSelector(store => store.scenes.current)
  return (
    <Layout>
      <Seo
        title={'ORACULO - the magical tool for UI designers & product teams'}
        description={'You can use ORACULO  for independent practice, design team building or to test communication and skills on job interview.'}
      />

      <SceneLoading />
      <SceneWelcome />
      <SceneTransition />
      <SceneGame />
      {/*<DebugTimeline  sid={currentSid }/>*/}
    </Layout>
  )
}

export default IndexPage
