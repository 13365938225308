// extracted by mini-css-extract-plugin
export var card = "card-module--card--TTLUX";
export var type_1 = "card-module--type_1--J74iw";
export var type_3 = "card-module--type_3--0Hv2d";
export var type_2 = "card-module--type_2--JrGiX";
export var pict = "card-module--pict--r+4E4";
export var caption = "card-module--caption--UXwxd";
export var wrap = "card-module--wrap--pVULi";
export var pictWrap = "card-module--pictWrap--+Kq9r";
export var text = "card-module--text--Sto2a";
export var time = "card-module--time--O7mWa";
export var bubble = "card-module--bubble--f1w2B";