import {useDispatch, useSelector} from "react-redux";
import {SID_GAME, SID_TRANSITION, SID_WELCOME_1, SID_WELCOME_2, SID_WELCOME_3,} from "./scenesTypes";
import Scene from "../components/scene/scene";
import React, {useEffect, useState} from "react";
import SceneControl from "../components/scene/sceneControl";
import {setScene} from "../redux/actions";
// import DebugTimeline from "../components/debugTimeline/debugTimeline";
import * as styles from "./sceneWelcome.module.scss";
import AnimeLottie from "../components/animeLottie/animeLottie";
import AnimeItem from "../components/animeItem/animeItem";
import Bubble from "../components/bubble/bubble";
// import RoundButton from "../components/roundButton/roundButton";
import Button from "../components/button/button";

export default function SceneWelcome() {
  useEffect(()=>{
    window.addEventListener('mousewheel', ()=>{
      // if (window.anim) {
      //   window.anim.play();
      //   window.anim = null
      // }
      // window.cycle = true
      // console.log('fun')
    })
  },[])
  return (
    <>
      <SceneWelcomeFirst/>
      <SceneWelcomeBig/>
      <SceneWelcomeLast/>
    </>
  )
}

export function SceneWelcomeFirst() {
  const currentSid = useSelector(store => store.scenes.current)
  const fullTime = useSelector(store => store.scenes[currentSid].fullTime)
  const dispatch = useDispatch()
  const options_2 = {
    scale: [
      {value: 4, duration: 0},
      {value: 1, duration: 200},
      {value: 1, duration: 800},
    ],
    translateX: [
      {value: '-100vw', duration: 0},
      {value: 0, duration: 200},
      {value: 0, duration: 800},
    ],
    rotate: [
      {value: '-10deg', duration: 0}
    ],
    opacity: [
      {value: 1, duration: 0},
      {value: 1, duration: 400},
      {value: 0, duration: 100},
      {value: 0, duration: 500},
    ]
  }

  const options_3 = {
    scale: [
      {value: 4, duration: 0},
      {value: 4, duration: 200}, // 200
      {value: 1, duration: 264}, // 400
      {value: 1, duration: 400}, // 400
    ],
    translateX: [
      {value: '-100vmax', duration: 0},
      {value: '-100vmax', duration: 200}, // 364
      {value: 0, duration: 264}, // 236
      {value: 0, duration: 436}, // 400
    ],
    rotate: [
      {value: '-4deg', duration: 0}
    ],
    opacity: [
      {value: 1, duration: 0},
      {value: 1, duration: 700},
      {value: 0, duration: 100},
      {value: 0, duration: 200},
    ]
  }
  const options_4 = {
    scale: [
      {value: 4, duration: 0},
      {value: 4, duration: 400},
      {value: 1, duration: 400},
      {value: 1, duration: 200},
    ],
    translateX: [
      {value: '-100vw', duration: 0},
      {value: '-100vw', duration: 605}, // 400
      {value: 0, duration: 195},
      {value: 0, duration: 200},
    ],
    rotate: [
      {value: '8deg', duration: 0}
    ]
  }

  return (
    <>
      { (currentSid.indexOf(SID_WELCOME_1) >= 0) && <>
        <Scene  sid={SID_WELCOME_1}>
          <AnimeLottie
            className={styles.animLottie}
            size={{w:'100vmin',h:'100vmin'}}
            fullTime={fullTime}
            options={{frame: [
                { value: 0, duration: 0 },
                { value: 250 },
              ]}}
            jsonAnim={'/lottie/oraculo-first_exp.json'}
          />
          <AnimeItem className={styles.bubbleLT} fullTime={fullTime} options={{...options_2}}>
            <Bubble className={styles.bubble_1}>
              hi! my name
              is ORACULO
            </Bubble>
          </AnimeItem>
          <AnimeItem className={styles.bubbleLB} fullTime={fullTime} options={{...options_3}}>
            <Bubble className={styles.bubble_2}>
              boost your creativity and design skills
            </Bubble>
          </AnimeItem>
          <AnimeItem className={styles.bubbleR} fullTime={fullTime} options={{...options_4}}>
            <Bubble className={styles.bubble_2} typeCorner={1}>
              become a real design ninja with
              my technique
            </Bubble>
          </AnimeItem>
          <AnimeItem className={styles.buttonNext} fullTime={fullTime} options={{}}>
            {/*<Button
                    onClick={
                      ()=>{
                        if (window.anim) {
                          window.anim.play();
                          window.anim = null
                        }
                      }
                    }
            >next</Button>*/}
            <Button
              className={'purple'}
              onClick={
                ()=>{
                  window.cycle = true
                }
              }
            >Next</Button>
          </AnimeItem>

          <SceneControl
            pauseFunN={[
              // {timestamp: 200, fun: (anim)=>{window.anim = anim }},
              // {timestamp: 500, fun: (anim)=>{window.anim = anim }},
              // {timestamp: 800, fun: (anim)=>{window.anim = anim }},
            ]}
            cycleFunN={[
              {timestamp: 200, start: 200, end: 364, funGap: funGap},
              {timestamp: 600, start: 600, end: 605, funGap: funGap},
              {timestamp: 800, start: 800, end: 910, funGap: funGap},
            ]}
            onTimeEnd={()=>{dispatch(setScene({sid: SID_WELCOME_2}))}}
          />
        </Scene>
        {/*<DebugTimeline  sid={SID_WELCOME_1}/>*/}
      </>}
    </>
  )
}
function funGap(){
  if (window.hasOwnProperty('cycle') && window.cycle) {
    window.cycle = false
    return true;
  }
  return false
}



export function SceneWelcomeBig() {
  const currentSid = useSelector(store => store.scenes.current)
  const fullTime = useSelector(store => store.scenes[currentSid].fullTime)
  const dispatch = useDispatch()
  return (
    <>
      { (currentSid.indexOf(SID_WELCOME_2) >= 0) && <>
        <Scene  sid={SID_WELCOME_2}>
          <AnimeLottie
            className={`${styles.animLottie} ${styles.big}`}
            fullTime={fullTime}
            options={{frame: [
                { value: 0, duration: 0 },
                { value: 60 },
              ]}}
            jsonAnim={'/lottie/big-face.json'}
          />
          <SceneControl onTimeEnd={()=>{dispatch(setScene({sid: SID_WELCOME_3}))}} />
        </Scene>
        {/*<DebugTimeline  sid={SID_WELCOME}/>*/}
      </>}
    </>
  )
}
export function SceneWelcomeLast() {
  const currentSid = useSelector(store => store.scenes.current)
  const fullTime = useSelector(store => store.scenes[currentSid].fullTime)
  const dispatch = useDispatch()

  const options_bubble = {
    scale: [
      {value: 4, duration: 0},
      {value: 1, duration: 150},
      {value: 1, duration: 3050},
    ],
    translateX: [
      {value: '100vw', duration: 0},
      {value: 0, duration: 150},
      {value: 0, duration: 3050},
    ],
    rotate: [
      {value: '5deg', duration: 0}
    ]
  }

  const options_button = {
    opacity: [
      {value: 0, duration: 0},
      {value: 0, duration: 400},
      {value: 1, duration: 100},
      {value: 1, duration: 2700},
    ],

  }

  return (
    <>
      { (currentSid.indexOf(SID_WELCOME_3) >= 0) && <>
        <Scene  sid={SID_WELCOME_3}>
          <AnimeLottie
            className={`${styles.animLottie} ${styles.last}`}
            fullTime={fullTime}
            options={{frame: [
                { value: 0, duration: 0 },
                { value: 800 },
              ]}}
            jsonAnim={'/lottie/oraculo-last_exp.json'}
          />
          <AnimeItem className={styles.bubbleR} fullTime={fullTime} options={{...options_bubble}}>
            <Bubble className={styles.bubble_2} typeCorner={1}>
              All you need is a great mood, 25 minutes, paper, and a pen;)
            </Bubble>
          </AnimeItem>
          <AnimeItem className={styles.buttonStart} fullTime={fullTime} options={{...options_button}}>
            <Button
              className={'yellow'}
              onClick={()=>{
                window.cycle = true
                // if (window.anim) {
                //   window.anim.play();
                //   window.anim = null
                // }
              }
              }
            >start a game</Button>
            {/*<Button
              onClick={
                ()=>{
                  window.cycle = true
                }
              }
            >next cycle</Button>*/}
          </AnimeItem>
          <SceneControl
            pauseFunN={[
              // {timestamp: 600, fun: (anim)=>{window.anim = anim}},
            ]}
            cycleFunN={[
              {timestamp: 600, start: 600, end: 3000, funGap: funGap, fun: ()=>{ dispatch(setScene({sid: SID_TRANSITION}))}},
            ]}
            onTimeEnd={()=>{dispatch(setScene({sid: SID_TRANSITION}))}} />
        </Scene>
        {/*<DebugTimeline  sid={SID_WELCOME_3}/>*/}
      </>}
    </>
  )
}