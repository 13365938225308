import {OPEN_POPUP} from "./actionTypes";

export const openPopup = content => {
  return {
    type: OPEN_POPUP,
    payload: {
      isPopup: true,
      compList: content.compList
    }
  }
}

export const closePopup = () => {
  return {
    type: OPEN_POPUP,
    payload: {
      isPopup: false
    }
  }
}

export const setTimeline = content => {
  // console.log('action', content.timeline)
  return {
    type: 'SET_TIMELINE',
    payload: {
      sid: content.sid,
      timeline: content.timeline
    }
  };
}

export const setScene = content => {
  return {
    type: 'SET_SCENE',
    payload: {
      sid: content.sid,
    }
  }
}