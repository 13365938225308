import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {SID_LOADING, SID_WELCOME_1} from "./scenesTypes";
import Scene from "../components/scene/scene";
import AnimeItem from "../components/animeItem/animeItem";
import AnimeLottie from "../components/animeLottie/animeLottie";
import DebugTimeline from "../components/debugTimeline/debugTimeline";
import SceneControl from "../components/scene/sceneControl";
import {setScene} from "../redux/actions";
import * as styles from "./sceneLoading.module.scss"

export default function SceneLoading() {
  const currentSid = useSelector(store => store.scenes.current)
  const fullTime = useSelector(store => store.scenes[currentSid].fullTime)
  const dispatch = useDispatch();

  return (
    <>
      { (currentSid.indexOf(SID_LOADING) >= 0) && <>
        <Scene addClass={styles.sceneBg} sid={SID_LOADING}>
          <AnimeLottie
            className={styles.animLottie}
            fullTime={fullTime}
            options={{frame: [
              { value: 0, duration: 0 },
              { value: 110, duration: 400  },
              { value: 128, duration: 150 },
            ]}}
            jsonAnim={'/lottie/load.json'}
          />
          <AnimeItem className={styles.text} fullTime={fullTime} options={{...options_2}}>
            please wait. potion is boiling
          </AnimeItem>

          <SceneControl onTimeEnd={()=>{dispatch(setScene({sid: SID_WELCOME_1}))}} />
        </Scene>
        {/*<DebugTimeline  sid={SID_LOADING}/>*/}
      </>}
    </>
  )
}

const options_2 = {
  // translateY: [
  //   { value: 40, duration: 0 },
  //   { value: 0, duration: 150 },
  //   { value: 0, duration: 700 },
  //   { value: -40, duration: 150 },
  //   { value: -40, duration: 3000 },
  // ],
  // opacity: [
  //   { value: 0, duration: 0 },
  //   { value: 1, duration: 150 },
  //   { value: 1, duration: 700 },
  //   { value: 0, duration: 150 },
  //   { value: 0, duration: 3000 }
  // ],
}