import {useDispatch, useSelector} from "react-redux";
import {SID_GAME, SID_LOADING, SID_TRANSITION} from "./scenesTypes";
import Scene from "../components/scene/scene";
import React from "react";
import AnimeLottie from "../components/animeLottie/animeLottie";
import * as styles from "./sceneLoading.module.scss";
import SceneControl from "../components/scene/sceneControl";
import {setScene} from "../redux/actions";
import DebugTimeline from "../components/debugTimeline/debugTimeline";

export default function SceneTransition() {
  const currentSid = useSelector(store => store.scenes.current)
  const fullTime = useSelector(store => store.scenes[currentSid].fullTime)
  const dispatch = useDispatch()
  return (
    <>
      { (currentSid.indexOf(SID_TRANSITION) >= 0) && <>
        <Scene  sid={SID_TRANSITION}>
          <AnimeLottie
            className={styles.animLottie}
            fullTime={fullTime}
            options={{frame: [
                { value: 0, duration: 0 },
                { value: 110 },
              ]}}
            jsonAnim={'/lottie/transmition.json'}
          />
          <SceneControl onTimeEnd={()=>{dispatch(setScene({sid: SID_GAME}))}} />
        </Scene>
        {/*<DebugTimeline  sid={SID_TRANSITION}/>*/}
      </>}
    </>
  )
}