import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import animBasic from "../../modules/animation/anim-basic";

const AnimeItem = ({
                     sid,
                     className,
                     children,
                     delay = 0 ,
                     start=0,
                     options={},
                     fullTime}) => {

  const elRef = useRef()
  const timeline = useSelector(state => state.scenes[sid].timeline);

  useEffect(()=>{
    const targets = elRef.current;
    let anime = null;
    if (targets) {
      anime = animBasic(targets, delay,{...options});
      targets.anime = anime;
    }
    return () => {
      if (anime) anime.remove(targets);
    };
  },[delay,options])

  useEffect(()=>{
    const targetsAnime = 'anime' in elRef.current ? elRef.current.anime : null;
    if (targetsAnime) {
      start <= timeline ?  targetsAnime.seek(targetsAnime.duration * (timeline - start) / fullTime)
      : targetsAnime.seek(0);
    }
  },[timeline, start, fullTime]);


  return (
    <div className={`${className}`} ref={elRef}> {children} </div>
  )
}

export default AnimeItem;