import * as React from "react"
import * as styles from "./header.module.scss"
import BigPopup from "../bigPopup/bigPopup";
// import {useState} from "react";
import dataJson from "/static/contant_about.json";
import {openPopup, setScene} from "../../redux/actions";
import {SID_LOADING, SID_WELCOME_1} from "../../compositions/scenesTypes";
import {useDispatch, useSelector} from "react-redux";

export default function Header() {
  const currentSid = useSelector(stage=>stage.scenes.current)
  const dispatch = useDispatch()
  return (
    <>
      { currentSid.indexOf(SID_LOADING)<0 && <>
        <div className={styles.header}>
          <div className={styles.wrap}>
            <div className={styles.logo} onClick={()=>{dispatch(setScene({sid: SID_WELCOME_1}))}}>
              <svg width="126" height="23" viewBox="0 0 126 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.04553 22.2305C14.6691 22.2305 18.3304 18.6649 18.3304 13.3285V13.1371C18.3304 7.72889 14.4059 4.5462 9.14126 4.5462C4.02024 4.5462 0 7.99212 0 13.2807V13.4721C0 18.6649 3.4459 22.2305 9.04553 22.2305ZM9.18912 17.9709C7.03542 17.9709 5.6714 16.3676 5.6714 13.4482V13.2567C5.6714 10.3373 7.03542 8.78182 9.14126 8.78182C11.295 8.78182 12.6829 10.3612 12.6829 13.2567V13.4482C12.6829 16.2958 11.3189 17.9709 9.18912 17.9709Z" fill="white"/>
                <path d="M26.4262 12.5628V8.87754H27.7184C29.1781 8.87754 29.92 9.35613 29.92 10.6005V10.6962C29.92 11.9406 29.2021 12.5628 27.6945 12.5628H26.4262ZM21.018 21.9194H26.4262V16.0565H27.2877L30.4704 21.9194H36.1657L32.2651 14.9318C33.9641 14.1661 35.1606 12.826 35.1606 10.4569V10.3612C35.1606 6.6999 32.7198 4.80943 28.0056 4.80943H21.018V21.9194Z" fill="white"/>
                <path d="M45.6889 9.18863L47.364 15.3865H43.9899L45.6889 9.18863ZM37.1459 21.9194H42.1951L42.9609 19.0717H48.3691L49.1588 21.9194H54.902L49.5417 4.80943H42.5302L37.1459 21.9194Z" fill="white"/>
                <path d="M64.5125 22.2305C69.0353 22.2305 72.3376 19.8614 72.6248 15.3626H67.2166C67.0251 17.0855 65.9244 17.8752 64.3928 17.8752C62.287 17.8752 61.1384 16.2958 61.1384 13.4482V13.2567C61.1384 10.3612 62.3349 8.82967 64.2971 8.82967C65.8047 8.82967 66.6423 9.71509 66.8098 11.2945H72.4094C72.0026 6.65204 68.8917 4.5462 64.2732 4.5462C59.224 4.5462 55.4191 7.99212 55.4191 13.2807V13.4721C55.4191 18.7128 58.3386 22.2305 64.5125 22.2305Z" fill="white"/>
                <path d="M82.7871 22.2305C87.5732 22.2305 90.6362 19.9571 90.6362 14.8122V4.80943H85.1802V14.6207C85.1802 16.8702 84.7015 17.9709 82.7871 17.9709C80.8488 17.9709 80.3702 16.8702 80.3702 14.6447V4.80943H74.962V14.86C74.962 19.7418 77.7858 22.2305 82.7871 22.2305Z" fill="white"/>
                <path d="M94.1148 21.9194H105.769V17.7316H99.5469V4.80943H94.1148V21.9194Z" fill="white"/>
                <path d="M116.19 22.2305C121.814 22.2305 125.475 18.6649 125.475 13.3285V13.1371C125.475 7.72889 121.551 4.5462 116.286 4.5462C111.165 4.5462 107.145 7.99212 107.145 13.2807V13.4721C107.145 18.6649 110.591 22.2305 116.19 22.2305ZM116.334 17.9709C114.18 17.9709 112.816 16.3676 112.816 13.4482V13.2567C112.816 10.3373 114.18 8.78182 116.286 8.78182C118.44 8.78182 119.828 10.3612 119.828 13.2567V13.4482C119.828 16.2958 118.464 17.9709 116.334 17.9709Z" fill="white"/>
                <circle cx="43.3334" cy="2.18432" r="2.18432" fill="white"/>
                <circle cx="48.7733" cy="2.18432" r="2.18432" fill="white"/>
              </svg>
            </div>
            <div className={styles.about} onClick={()=>{dispatch(openPopup({compList: dataJson}))}}> about </div>
          </div>
        </div>

        <BigPopup />
      </>}
    </>
  )
}
