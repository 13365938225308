import React from "react";
import * as styles from "./button.module.scss"

export default function Button({onClick, children, className}) {
  return (
    <>
     <button className={`${styles.button} ${className ? styles[className]:''}`} onClick={()=>{onClick();}}>
       {children}
     </button>
    </>
  )
}