import React from "react";
import * as styles from "./bubble.module.scss";

export default function Bubble({typeCorner=0, className, children}) {
  const cornerClass = ['cr','cl']
  return (<>
    <div className={`${styles.bubble} ${className} ${styles[cornerClass[typeCorner]]}`}>
      {children}
    </div>
  </>)
}