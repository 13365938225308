import * as styles from "../../compositions/sceneGame.module.scss";
import HeroCard from "../heroCard/heroCard";
import React, {useEffect} from "react";
import {getRandomArray, getRandomFromArray} from "../../modules/mathfun/mathfun";
import RoundButton from "../roundButton/roundButton";


export default function Game() {
  const [label, setLabel] = React.useState('Label')
  const [initExit, setInitExit] = React.useState(false)
  const [emojiN, setEmojiN] = React.useState(getRandomArray(3,1,32))

  const [designD, setDesignD] = React.useState(null)
  const [productD, setProductD] = React.useState(null)
  const [usersD, setUsersD] = React.useState(null)
  const [captions, setCaptions] = React.useState({
    designD: null,
    productD: null,
    usersD: null,
  })

  React.useEffect(()=>{
    if (!label) {
      setTimeout(()=>{
        setLabel(`${Math.random()}`)
        setEmojiN(getRandomArray(3,1,32))
        setInitExit(false);
        setCaptions({
          designD: getRandomFromArray(designD),
          productD: getRandomFromArray(productD),
          usersD: getRandomFromArray(usersD),
        })
      }, 300);
    }

  },[label])

  useEffect(()=>{
    if (!designD) {
      fetch('/cards-data/design.json')
        .then(r=>r.json())
        .then(d=>{
          setCaptions(s => (
            { ...s,
              designD: getRandomFromArray(d)
            }
          ))
          setDesignD(d)
        })
    }
    if (!productD) {
      fetch('/cards-data/product.json')
        .then(r=>r.json())
        .then(d=> {
          setCaptions(s => (
            { ...s,
              productD: getRandomFromArray(d)
            }
          ))
          setProductD(d)
        })
    }
    if (!usersD) {
      fetch('/cards-data/users.json')
        .then(r=>r.json())
        .then(d=> {
          setCaptions(s => (
            { ...s,
              usersD: getRandomFromArray(d)
            }
          ))
          setUsersD(d)
        })
    }
  },[])

  return (
    <>
      <div className={styles.wrapCards}>
        {label && <>
          <HeroCard emojiUrl={`/cards-data/e-${emojiN[0]}.png`}  label={'Design'} inAnim={initExit ? 'outCard':'showLeft'} caption={captions.designD}/>
          <HeroCard emojiUrl={`/cards-data/e-${emojiN[1]}.png`}  label={'Product'} inAnim={initExit ? 'outCard':'showCenter'} caption={captions.productD}/>
          <HeroCard emojiUrl={`/cards-data/e-${emojiN[2]}.png`} label={'Users'} inAnim={initExit ? 'outCard':'showRight'} caption={captions.usersD}/>
        </>}
      </div>
      <RoundButton onClick={()=>{setInitExit(true); setTimeout(()=>{setLabel(null)}, 1000)}}/>
    </>
  )
}