export function getRandomArbitrary(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

export function getRandomFromArray(arr) {
  let result = null;
  if (Array.isArray(arr) && (arr.length > 0)) {
    result = arr[Math.round(Math.random() * (arr.length - 1))]
  }
  return result
}

export function getRandomArray(size,min,max) {
  let result = new Array(size).fill(min)

  if (size-1 > max-min) {
    console.log('Incorrect values')
    return result
  }

  result.forEach((item, k)=>{
    rec(result,k,min,max);
  })

  function rec(arr,k,min,max){
    let a = getRandomArbitrary(min, max);
    arr[k]=a
    for (let i=0; i<k; i++) {
      if (arr[i] === a) {
        rec(arr,k,min,max)
      }
    }
  }

  return result
}