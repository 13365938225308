import {Helmet} from "react-helmet";
import * as React from "react";

const Seo = ({title, description})=>{
  return (
    <Helmet title={title} htmlAttributes={{ lang: 'en',}}>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={'/oraculo_1200x630.png'} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

export default Seo;